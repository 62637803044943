import * as React from "react"

function BuildingTypeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.625 9.167h.917v.916H.458v-.916h.917V1.375a.458.458 0 01.458-.458h7.334a.458.458 0 01.458.458v7.792zm-.917 0V1.833H2.292v7.334h6.416zM3.667 5.042h1.375v.916H3.667v-.916zm0-1.834h1.375v.917H3.667v-.917zm0 3.667h1.375v.917H3.667v-.917zm2.291 0h1.375v.917H5.958v-.917zm0-1.833h1.375v.916H5.958v-.916zm0-1.834h1.375v.917H5.958v-.917z"
        fill="currentColor"
      />
    </svg>
  )
}

export default BuildingTypeIcon
