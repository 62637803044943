import * as React from "react"

function LocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 2.5L4.5 1l3 1.5 3.152-1.35a.25.25 0 01.348.23V9.5L7.5 11l-3-1.5-3.151 1.35A.25.25 0 011 10.62V2.5zm7 7.197l2-.856V2.517l-2 .857v6.323zm-1-.065V3.368l-2-1v6.264l2 1zM4 8.627V2.301L2 3.16v6.325l2-.857z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LocationIcon
