import React, { ReactNode, useCallback } from 'react'
import LegacyCarousel from '../../../LegacyCarousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import BuildingTypeIcon from '../../../icons/BuildingTypeIcon'
import LocationIcon from '../../../icons/LocationIcon'
import { HomepageProps, Project } from '../../../../pages'
import { useInView } from 'react-intersection-observer'
import Image from 'next/image'

type Props = {
    promotions: HomepageProps['promotionsData']
    isMobile: boolean
    breakpoint: string
    projects: Project[]
    classes: Record<string, any>
}

const ExploreProject = (props: Props) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    })

    const renderProjectElement = useCallback((index: number, imageUrl: string, title: string, type: string, location: string, description: string, url: string, isClamped: boolean) =>
        <>
            <div className="absolute inset-0">
                <Image src={imageUrl} className="w-full h-full object-cover object-center" layout="fill" alt={title} />
            </div>
            <div className={`text-white absolute inset-0 flex flex-col items-start justify-end px-3 lg:px-5 pb-3 lg:pb-4 transition-opacity duration-150 ease-linear ${props.classes['project-card-layer-one']}`} style={{ background: 'linear-gradient(360deg, rgba(1, 1, 1, 0.8) 0%, rgba(1, 1, 1, 0) 60.89%)' }}>
                <p className="font-bold text-sm lg:text-22px mb-1 lg:mb-2">{title}</p>
                <div className="w-full grid grid-cols-2 gap-1">
                    <div className="flex items-center">
                        <BuildingTypeIcon className="text-2xs lg:text-xs text-white mr-1" />
                        <p className="text-2xs lg:text-xs">
                            {type}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <LocationIcon className="text-2xs lg:text-xs text-white mr-1" />
                        <p className="text-2xs lg:text-xs truncate">
                            {location}
                        </p>
                    </div>
                </div>
            </div>
            <div className={`text-white absolute inset-0 flex flex-col items-start justify-end px-3 lg:px-5 pb-3 lg:pb-4 transition-opacity duration-150 ease-linear ${props.classes['project-card-layer-two']}`} style={{ background: 'linear-gradient(0deg, rgba(1, 1, 1, 0.8) 23.76%, rgba(1, 1, 1, 0) 100%)' }}>
                <p className="font-bold text-sm lg:text-22px mb-1 lg:mb-2">{title}</p>
                <div className="w-full grid grid-cols-2 gap-1 mb-1 lg:mb-4">
                    <div className="flex items-center">
                        <BuildingTypeIcon className="text-2xs lg:text-xs text-white mr-1" />
                        <p className="text-2xs lg:text-xs">
                            {type}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <LocationIcon className="text-2xs lg:text-xs text-white mr-1" />
                        <p className="text-2xs lg:text-xs truncate">
                            {location}
                        </p>
                    </div>
                </div>
                <p className="text-2xs lg:text-xs mb-2 lg:mb-5 mulitline-elipsis" style={{ WebkitLineClamp: !isClamped ? undefined : props.isMobile ? 2 : 4 }}>
                    {description}
                </p>
                <a href={url} className={`text-2xs lg:text-xs leading-none px-3 lg:px-4 py-1.5 lg:py-2 mb-0 lg:mb-2 rounded bg-main lg:hover:bg-main-dark transition-colors duration-150 ease-linear outline-none focus:outline-none`}>
                    Explore More
                </a>
            </div>
        </>,
        [props.classes]
    )

    const renderProjects = useCallback(() => {
        let output: ReactNode[] = []

        const length = props.projects.length
        const noOfColumn = Math.ceil(length / 3)
        let currentItem = 0

        for (let idx = 0; idx < noOfColumn; idx++) {
            output.push(
                <div key={idx} className="w-full md:w-1/2 lg:w-1/2 flex-shrink-0 grid grid-cols-2 grid-rows-2 gap-3 px-1.5">
                    {
                        currentItem < props.projects.length &&
                        <div className={`row-span-2 pt-184% relative rounded overflow-hidden ${props.classes['project-card']}`}>
                            {
                                renderProjectElement(
                                    currentItem,
                                    props.projects[currentItem].thumbnail_image,
                                    props.projects[currentItem].name,
                                    props.projects[currentItem].is_apartment_project === 1 ? 'Apartment' : 'Residential',
                                    props.projects[currentItem].city_name,
                                    props.projects[currentItem].highlights || '',
                                    props.projects[currentItem].url,
                                    false,
                                )
                            }
                        </div>
                    }
                    {
                        (currentItem + 1) < props.projects.length ?
                            currentItem + 1 === props.projects.length - 1 ?
                                <div className={`row-span-2 pt-184% relative rounded overflow-hidden ${props.classes['project-card']}`}>
                                    {
                                        renderProjectElement(
                                            currentItem + 1,
                                            props.projects[currentItem + 1].thumbnail_image,
                                            props.projects[currentItem + 1].name,
                                            props.projects[currentItem + 1].is_apartment_project === 1 ? 'Apartment' : 'Residential',
                                            props.projects[currentItem + 1].city_name,
                                            props.projects[currentItem + 1].highlights || '',
                                            props.projects[currentItem + 1].url,
                                            false,
                                        )
                                    }
                                </div>
                                :
                                <div className={`w-full h-full relative rounded overflow-hidden ${props.classes['project-card']}`}>
                                    {
                                        renderProjectElement(
                                            currentItem + 1,
                                            props.projects[currentItem + 1].thumbnail_image,
                                            props.projects[currentItem + 1].name,
                                            props.projects[currentItem + 1].is_apartment_project === 1 ? 'Apartment' : 'Residential',
                                            props.projects[currentItem + 1].city_name,
                                            props.projects[currentItem + 1].highlights || '',
                                            props.projects[currentItem + 1].url,
                                            true,
                                        )
                                    }
                                </div>
                            :
                            null
                    }
                    {
                        (currentItem + 2) < props.projects.length &&
                        <div className={`w-full h-full relative rounded overflow-hidden ${props.classes['project-card']}`}>
                            {
                                renderProjectElement(
                                    currentItem + 2,
                                    props.projects[currentItem + 2].thumbnail_image,
                                    props.projects[currentItem + 2].name,
                                    props.projects[currentItem + 2].is_apartment_project === 1 ? 'Apartment' : 'Residential',
                                    props.projects[currentItem + 2].city_name,
                                    props.projects[currentItem + 2].highlights || '',
                                    props.projects[currentItem + 2].url,
                                    true,
                                )
                            }
                        </div>
                    }
                </div>
            )
            currentItem = currentItem + 3
        }

        return output
    }, [props.projects])

    return (
        <div className={`pb-10 lg:pb-16 ${props.promotions.length === 0 ? 'pt-8' : ''} container`}>
            <h2 ref={ref} className="text-lg text-black-#3D3D3D lg:text-32px font-bold leading-snug mb-0 lg:mb-1.5">Explore Sinar Mas Land Project</h2>
            <p className="text-xs lg:text-base text-gray-#8F8F8F mb-6 lg:mb-8">
                Sinar Mas Land always has new projects in development, there<br></br>is something for everyone!
            </p>
            {
                inView &&
                <div className="-mx-5 md:-mx-2">
                    <LegacyCarousel
                        show={props.isMobile ? 1 : 2}
                        isRepeatedProps={['xs', 'sm', 'md'].includes(props.breakpoint)}
                        withIndicator={['xs', 'sm', 'md'].includes(props.breakpoint)}
                        indicatorSpacing="pt-5"
                        containerClasses="px-4 lg:px-0"
                        leftArrow={prev =>
                            <div className="hidden lg:block absolute left-0 z-1 transform top-1/2 -translate-y-1/2 -translate-x-1/2">
                                <button onClick={prev} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                    <FontAwesomeIcon icon={faAngleLeft} width="0" className="text-white" size="lg" />
                                </button>
                            </div>
                        }
                        rightArrow={next =>
                            <div className="hidden lg:block absolute right-0 z-1 transform top-1/2 -translate-y-1/2 translate-x-1/2">
                                <button onClick={next} className="bg-main hover:bg-main-dark active:bg-main-dark w-47px h-47px rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                                    <FontAwesomeIcon icon={faAngleRight} width="0" className="text-white" size="lg" />
                                </button>
                            </div>
                        }
                    >
                        {renderProjects()}
                    </LegacyCarousel>
                </div>
            }
        </div>
    )
}

export default React.memo(ExploreProject) 